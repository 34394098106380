@import url("https://fonts.googleapis.com/css2?family=Prompt:ital@0;1&display=swap");

html,
body {
  font-family: "Prompt", sans-serif;
  min-height: 100%;
  background: url("../rocket/bg_decktop.png") no-repeat center center fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #052c58;
}
.main-container {
  main > section {
    padding-bottom: 10px;
    min-height: 500px;
  }
  .page-title {
    margin-left: 50px;
    margin-bottom: 10px;
  }
}
footer {
  margin-top: 20px;
  // background-color: rgba(45, 105, 168, 0.4);
}
.login-container {
  top: 50%;
  left: 50%;
  position: relative;
  .login-bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../rocket/bg_rocket_login.png");
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;
    width: 340px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 35px;
  }
}

.game-bg {
  position: fixed;
  background-image: url("../rocket/bg_rocket_game.png");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  body {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .login-container {
    top: 50%;
    left: 50%;
    position: relative;
    .login-bg {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("../rocket/bg_rocket_login.png");
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      height: 460px;
      width: 310px;
      padding-top: 10px;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
